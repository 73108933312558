const $dropdowns = document.querySelectorAll('.act-dropdown');

$dropdowns.forEach(($el) => {
    const fullscreenBreakpoint = $el.dataset.fullscreenBreakpoint ? $el.dataset.fullscreenBreakpoint : '479px';
    const dropdown_media_query = window.matchMedia('(max-width: '+fullscreenBreakpoint+')');
    if (dropdown_media_query.matches) {
        $el.classList.add('act-dropdown--fullscreen');
    }
    dropdown_media_query.addEventListener('change', (e) => {
        console.log($el.dataset, fullscreenBreakpoint);
        if (e.matches) {
            $el.classList.add('act-dropdown--fullscreen');
        } else {
            $el.classList.remove('act-dropdown--fullscreen');
        }
    });


});


document.body.addEventListener('click', (e) => {
    const $opening_dropdown = e.target.closest('.act-dropdown');
    const current_dropdown_opened = $opening_dropdown ? $opening_dropdown.classList.contains('act-dropdown--is-opened') : true;
    const $current_trigger = e.target.closest('.act-dropdown__trigger');
    const $opened_dropdown = document.querySelectorAll('.act-dropdown--is-opened');
    if ($opened_dropdown.length) {
        $opened_dropdown.forEach(($el) => {
            $el.classList.toggle('act-dropdown--is-opened');
        });
    }
    
    if ($current_trigger) {
        if (current_dropdown_opened) {
            $opening_dropdown.classList.remove('act-dropdown--is-opened');
        } else {
            $opening_dropdown.classList.add('act-dropdown--is-opened');
        }
        return;
    } else if (e.target.closest('.act-dropdown__drawer') && ! e.target.closest('.act-dropdown__close')) {
        if (current_dropdown_opened) {
            $opening_dropdown.classList.add('act-dropdown--is-opened');
        } else {
            $opening_dropdown.classList.remove('act-dropdown--is-opened');
        }
    }

});